import React,{useContext, useEffect,useState,useRef} from 'react'
import axios from 'axios'
import {Link,NavLink,useNavigate,useParams } from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal} from 'react-bootstrap';
import logo from '../logo.png'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'


export default function CreateList() {
    const navigate=useNavigate ()
    const [countries,setCountries]=useState([])//useState(JSON.parse(sessionStorage.getItem('appCountries')))
    const [appPositions,setAppPositions]=useState([])
    const [positions,setPositions]=useState([])
    const [queryRsults,setQueryResults]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [data,setData]=useState([])
    const [a1,setA1]=useState(0)
    const [a2,setA2]=useState(0)
    const [h1,setH1]=useState(0)
    const [h2,setH2]=useState(0)
    const [w1,setW1]=useState(0)
    const [w2,setW2]=useState(0)
    const [o,setO]=useState(0)
    const [s,setS]=useState('')
    const [listName,setListName]=useState('Create New List')
    const [sqlCriteria,setSqlCriteria]=useState('')
    const [show,setShow]=useState(false)
    const resultsTbl=useRef()

    const {listID}=useParams()
    // const a1=useRef(0)
    // const a2=useRef(0)
    // const h1=useRef(0)
    // const h2=useRef(0)
    // const w1=useRef(0)
    // const w2=useRef(0)
    // const o=useRef(0)
    // const s=useRef('')


    const columns = [
        {
            name: '#',
            selector: (row,index) =>  index+1,
            sortable: true,
            maxWidth:'5px'
        },
        {
            name: '',
            selector: row => <Button variant="secondary" onClick={()=>navigate('/user/'+row.UUID)}><FontAwesomeIcon icon={faEdit}/></Button>,
            sortable: true,
            maxWidth:'5px'
        },    
        {
            name: 'Name',
            selector: (row,index) =>  row.FirstName+ ' '+row.LastName,
            sortable: true
            
        },
        {
            name: 'Age',
            selector: (row,index) =>  row.Age,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Country',
            selector: (row,index) =>  row.Country,
            sortable: true,
            maxWidth:'50px'
        },     
        {
            name: 'Position',
            selector: (row,index) =>  row.Position,
            sortable: true,
            maxWidth:'100px'
        }, 
        {
            name: 'Orientation',
            selector: (row,index) =>  row.LeftRight==1 ? 'Right' : row.LeftRight==2 ?  'Left' : 'Both Left & Right' ,
            sortable: true,
            maxWidth:'50px'
        },   
        {
            name: 'Height',
            selector: (row,index) =>  row.Height,
            sortable: true,
            maxWidth:'25px'
        },  
        {
            name: 'Weight',
            selector: (row,index) =>  row.Weight,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Team',
            selector: (row,index) =>  row.Team,
            sortable: true
        } ,
        {
            name: 'Points',
            selector: (row,index) =>  row.Points,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Posts',
            selector: (row,index) =>  row.Posts,
            sortable: true,
            maxWidth:'25px'
        }, 
        {
            name: 'Challenges',
            selector: (row,index) =>  row.Challenges,
            sortable: true,
            maxWidth:'25px'
        },         
        
    ]


    const fileName = 'list'+Date.now()  
    const exportType = 'xls'  
    // const data=[	{
      // 	EponCatGr: "ABER S.A"
      // },
      // {
      // 	EponCatGr: "ACMA"
    // }]
    const   ExportToExcel = () => {  
      const excelResults=queryRsults.map(item=>{
        return(
            {
                Name:item.FirstName+ ' '+item.LastName,
                Age:item.Age,
                Country:item.Country,
                Position:item.Position,
                Orientation:item.LeftRight==1 ? 'Right' : item.LeftRight==2 ?  'Left' : 'Both Left & Right',
                Height:item.Height,
                Weight:item.Weight,
                Points:item.Points,
                NumberOfPosts:item.Posts,
                NumberOfChallenges:item.Challenges


            }
        )
      })
      exportFromJSON({ data:excelResults, fileName, exportType })  
    }  

    const handleShow=()=>{
        setShow(true)
    }

    const handleClose=()=>{
        setShow(false)
    }

    useEffect(()=>{
       // console.log(countries)
        loadPositions()
        if (listID){
            loadList()
        }
    },[])


    // useEffect(()=>{
    //     if (listID){
    //         createSQL()
    //     }
    // },[queryRsults])

    const loadList= ()=>{
        
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/listscriteria/list/'+listID,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                    }            
                })
            .then(res=>{
                if (res.data.err==0){

                setCountries(res.data.results.countries)
                setPositions(res.data.results.positions)
                    setListName(res.data.results.listName)
                    setA1(res.data.results.a1)
                    setA2(res.data.results.a2)
                    setH1(res.data.results.h1)
                    setH2(res.data.results.h2)
                    setW1(res.data.results.w1)
                    setW2(res.data.results.w2)
                    setO(res.data.results.o)
                    setS(res.data.results.s)

                }else{
                alert('An error has occured')
                }
                
                setIsLoading(false)
                
            }).catch(err=>{
                setIsLoading(false)
                console.log(err)
            }) 


        //   createSQL()
        //   window.scrollBy({
        //       top: 700, // Scroll down by 200 pixels
        //       behavior: 'smooth' // for smooth scrolling
        //     })
      }


    const loadPositions=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/listscriteria/positions/'+sessionStorage.getItem('SportID'),{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                }            
            })
          .then(res=>{

            setAppPositions(res.data.results)
            setIsLoading(false)
            
          }).catch(err=>{
              setIsLoading(false)
              console.log(err)
          }) 
    }

    const positionsList=appPositions.map(item=>{
        return(
            {label:item.Title, value:item.PositionID}
        )
    })


    const createSQL=()=>{


        var sqlCountries=''
        var sql=''
        const SportID=sessionStorage.getItem('SportID')

        countries.forEach((item,index)=>{
            if (index==0){
                sqlCountries=sqlCountries+ ' CountryIDINT='+item.value
            }else{  
                sqlCountries=sqlCountries+ ' OR CountryIDINT='+item.value
            }
        })
        if (sqlCountries.length>0){
            sqlCountries='('+sqlCountries+')'
        }
        sql=sqlCountries

        var sqlPositions=''
        positions.forEach((item,index)=>{
            if (index==0){
                sqlPositions=sqlPositions+ ' Users.PositionID='+item.value
            }else{  
                sqlPositions=sqlPositions+ ' OR Users.PositionID='+item.value
            }
        })
        if (sqlPositions.length>0){
            sqlPositions='('+sqlPositions+')'
        }
        if (sql.length>0 && sqlPositions.length>0){
            sql=sql+' AND '+sqlPositions
        }else{
            sql=sql+sqlPositions
        }
        
        var sqlHeight1=''
        if (h1>0){
            sqlHeight1=' Height>='+h1*100
        }
        if (sql.length>0 && sqlHeight1.length>0){
            sql=sql+' AND '+sqlHeight1
        }else{
            sql=sql+sqlHeight1
        }        

        var sqlHeight2=''
        if (h2>0){
            sqlHeight2=' Height<='+h2*100
        }
        if (sql.length>0 && sqlHeight2.length>0){
            sql=sql+' AND '+sqlHeight2
        }else{
            sql=sql+sqlHeight2
        }  


        var sqlWeight1=''
        if (w1>0){
            sqlWeight1=' Weight>='+w1
        }
        if (sql.length>0 && sqlWeight1.length>0){
            sql=sql+' AND '+sqlWeight1
        }else{
            sql=sql+sqlWeight1
        }        

        var sqlWeight2=''
        if (w2>0){
            sqlWeight2=' Weight<='+w2
        }
        if (sql.length>0 && sqlWeight2.length>0){
            sql=sql+' AND '+sqlWeight2
        }else{
            sql=sql+sqlWeight2
        }  


        var sqlOrientation=''
        if (o>0){
            sqlOrientation=' LeftRight='+o
        }
        if (sql.length>0 && sqlOrientation.length>0){
            sql=sql+' AND '+sqlOrientation
        }else{
            sql=sql+sqlOrientation
        }  



        setSqlCriteria(sql)

        axios.post(process.env.REACT_APP_API_SERVER+'manager/listscriteria/results',{sql,SportID},{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                }            
            })
          .then(res=>{
            let listResults=res.data.results
            let results=res.data.results
            const todayDate = moment().year()
            
            results.forEach(item=>{
                const dateOfBirth=moment(item.DateOfBirth).year()
                item.Age=todayDate-dateOfBirth
                //console.log(todayDate-dateOfBirth)
            })
            
            if (a1>0){
                listResults=listResults.filter(item=>item.Age>=a1)
                //results=[...listResults]
            }
            if (a2>0){
                listResults=listResults.filter(item=>item.Age<=a2)
                //results=[...listResults]
            }
            if (s.length>0){
                listResults=listResults.filter(item=>item.SearchStr.includes(s.toUpperCase()))
                //results=[...listResults]
            }
            //console.log(listResults)
            

            if (listResults.length>0){
                alert('Query returned '+listResults.length+' results. Scroll down to view results.')
                setQueryResults(listResults)
                //resultsTbl.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }else{
                setQueryResults([])
                alert('Query returned no results')
            }

            //console.log(listResults)

          }).catch(err=>{

          }) 
    }

    const saveList=(e)=>{
        e.preventDefault()

        const listObj={
            id:listID ? listID : 0,
            countries:JSON.stringify(countries),
            positions:JSON.stringify(positions),
            a1,
            a2,
            h1,
            h2,
            w1,
            w2,
            s,
            o,
            listName,
            uid:sessionStorage.getItem('userToken')
        }


        axios.post(process.env.REACT_APP_API_SERVER+'manager/listscriteria/lists',listObj,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                }            
            })
          .then(res=>{

           if (res.data.err==0){
            setShow(false)
            navigate('/lists')
           }else{
            alert('An Error has occured')
           }
            
          }).catch(err=>{
            alert('An Error has occured')
          })

    }

    const delList=()=>{
        if (window.confirm('DELETE LIST, are you sure?')){
            setIsLoading(true)
            axios.delete(process.env.REACT_APP_API_SERVER+'manager/listscriteria/list/'+listID,{
                headers: {
                    'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
                    }            
                })
              .then(res=>{
                if (res.data.err==0){
    
                    navigate('/lists')
                }else{
                  alert('An error has occured')
                }
                
                setIsLoading(false)
                
              }).catch(err=>{
                  setIsLoading(false)
                  console.log(err)
              })             
        }
    }

  return (


    <Container fluid>

        <br/>
    <Card>

      <Card.Body>
        <Card.Title>
            <table width="100%">
                <tr>
                    <td width="20"></td>
                    <td>
                       {listName} 
                    </td>
                    <td align="right">
                        <Button onClick={()=>navigate('/lists')} variant="secondary">Back to Players' Lists</Button>
                    </td>
                    <td width="20"></td>
                </tr>
            </table>

        </Card.Title>
        <hr/>
        <Form>
            <Form.Group className="mb-3">
                <Form.Label>Select Country<font color="orange"> <i>(required)</i></font></Form.Label>
                <MultiSelect
                    hasSelectAll={false}
                    options={JSON.parse(sessionStorage.getItem('appCountries'))}
                    value={countries}
                    onChange={setCountries}
                    labelledBy="Select"

                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Positions</Form.Label>
                <MultiSelect
                    hasSelectAll={false}
                    options={positionsList}
                    value={positions}
                    onChange={setPositions}
                    labelledBy="Select"

                />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <table>
                    <tr>
                        <td width="300">
                        <Form.Label>Age (equal or greater than)</Form.Label>
                        <Form.Control value={a1} onChange={(e)=>setA1(e.target.value)} defaultValue={0} type="number" min={11} step={1} style={{width:'150px'}}/>
                        </td>
                        <td width="30"></td>
                        <td width="300">
                        <Form.Label>Age (equal or less than)</Form.Label>
                        <Form.Control value={a2} onChange={(e)=>setA2(e.target.value)}  defaultValue={0} type="number" min={11} step={1} style={{width:'150px'}}/>
                        </td>
                    </tr>

                </table>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <table>
                    <tr>
                        <td width="300">
                        <Form.Label>Height (equal or greater than)</Form.Label>
                        <Form.Control value={h1} onChange={(e)=>setH1(e.target.value)}  defaultValue={0} type="number" min={0} step={0.1} style={{width:'150px'}}/>
                        </td>
                        <td width="30"></td>
                        <td width="300">
                        <Form.Label>Height (equal or less than)</Form.Label>
                        <Form.Control value={h2} onChange={(e)=>setH2(e.target.value)}  defaultValue={0} type="number" min={0} step={0.1} style={{width:'150px'}}/>
                        </td>
                    </tr>

                </table>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <table>
                    <tr>
                        <td width="300">
                        <Form.Label>Weight (equal or greater than)</Form.Label>
                        <Form.Control value={w1} onChange={(e)=>setW1(e.target.value)}  defaultValue={0} type="number" min={0} step={1} style={{width:'150px'}}/>
                        </td>
                        <td width="30"></td>
                        <td width="300">
                        <Form.Label>Weight (equal or less than)</Form.Label>
                        <Form.Control value={w2} onChange={(e)=>setW2(e.target.value)}  defaultValue={0} type="number" min={0} step={1} style={{width:'150px'}}/>
                        </td>
                    </tr>

                </table>

            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name or Nickname</Form.Label>
                <Form.Control type="text" maxLength={50} value={s} onChange={(e)=>setS(e.target.value)}/>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Orientation</Form.Label>
            <Form.Select value={o} onChange={(e)=>setO(e.target.value)}  aria-label="Default select example">
                <option value='0'>[Select Orientation]</option>
                <option value='1'>Right Handed/Footed</option>
                <option value='2'>Left Handed/Footed</option>
                <option value='3'>Both L-R Handed/Footed</option>
            </Form.Select>
            </Form.Group>





            <p align="right">
                {listID &&
                <>
                <Button variant="danger" onClick={()=>delList()}>Delete List</Button>
                &nbsp;&nbsp;
                </>
                }
                <Button onClick={createSQL} variant="dark" disabled={countries.length==0}>View Results</Button>
                &nbsp;&nbsp;
                <Button onClick={handleShow} variant="success" disabled={countries.length==0} style={{width:'200px'}}><b>Save List</b></Button>
            </p>

        </Form>
        {queryRsults.length>0 &&
        <>
        <br/><br/>
        <div ref={resultsTbl}>
        <Alert variant="light">
            <table width="100%">
                <tr>
                    <td width="20"></td>
                    <td><b>Results</b></td>
                    <td align="right">
                    <Button variant="secondary" onClick={ExportToExcel}>Export To Excel File</Button> 
                    </td>
                    <td width="20"></td>
                </tr>
            
            </table>

        </Alert>
        
        <DataTable
                columns={columns}
                data={queryRsults}
                highlightOnHover
                
                //expandableRows
                //expandableRowsComponent={ExpandedComponent}
                //pagination
        />  
        </div>
        </>
        }
      </Card.Body>
    </Card>


    <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>

                </Modal.Header>
                
                <Modal.Body>
                <Form onSubmit={saveList}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>List Name</Form.Label>
                    <Form.Control required type="text" maxLength={50} value={listName} onChange={(e)=>setListName(e.target.value)}/>
                    </Form.Group>

                    <p align="right">
                        <Button variant="success" type="submit">Save List</Button>
                    </p>
                </Form>
                </Modal.Body>

            </Modal>   

    </Container>
  )
}
