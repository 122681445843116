import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import VideoPlayer from 'react-video-js-player';

export default function UserPosts(params) {
    const [posts,setPosts]=useState([])
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        loadData()
    },[])
    

    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/userposts/'+params.uuid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
    
    
            //console.log(res.data)
            setPosts(res.data.results)
    
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }

    const postsList=posts.map(item=>{
        return(
            <Col md={4} style={{padding:'20px'}}>
            <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0',width:'420px',alignContent:'center',alignItems:'center'}}>

            <Card.Body>
                <Card.Title>

                    {item.Descr}
                </Card.Title>
                <Card.Text>

                    {item.Photo.length>0 &&
                        <img src={process.env.REACT_APP_API_SERVER+'postfiles/'+item.UUID+'/123'} width="400"/>
                    
                    
                    }

                    {item.VideoID.length>0 &&

                        <VideoPlayer
                        controls={true}
                        src={`${process.env.REACT_APP_VIDEO_BASE_URL}${item.VideoID.split('.')[0]}.smil/playlist.m3u8`}
                        poster={process.env.REACT_APP_VIDEOSERVER_API+''+item.VideoID.split('.')[0]+'.jpg'}
                        width="720"
                        height="420"
                        // onReady={this.onPlayerReady.bind(this)}
                        // onPlay={this.onVideoPlay.bind(this)}
                        // onPause={this.onVideoPause.bind(this)}
                        // onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                        // onSeeking={this.onVideoSeeking.bind(this)}
                        // onSeeked={this.onVideoSeeked.bind(this)}
                        // onEnd={this.onVideoEnd.bind(this)}
                    />                    
                    
                    }
                </Card.Text>

            </Card.Body>
            </Card>                

            </Col>
        )
    })

  return (
    <>
    {postsList}
    </>
  )
}
