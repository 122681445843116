import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from '../logo.png'
import PopularPlayers from './PopularPlayers'
import PopularCountries from './PopularCountries'


export default function Dashboard() {
  return (
    <Container fluid>

      <Row style={{padding:5}}>
        <Col md={4} style={{padding:10}}>
        <PopularPlayers/>
        </Col>  
        <Col md={4} style={{padding:10}}>
        <PopularCountries/>
        </Col>
        <Col md={4} style={{padding:10}}>
        {/* <PopularPlayers/> */}
        </Col>
      </Row>
    </Container>
  )
}
