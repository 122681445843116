import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { UserContext } from './context/UserContext'
import axios from 'axios'
import {Alert,Container,Row,Col,Form,Button,Table,Card} from 'react-bootstrap'
import Logo from './logo.png'
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

export default function Login() {
  const {isAuth,setIsAuth}=useContext(UserContext)
  const {apiToken,setApiToken} = useContext(UserContext)
  const [loginError,setLoginError]=useState(false)
  const [loginSuccess,setLoginSuccess]=useState(false)
  const [msg,setMsg]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const {userToken,setUserToken} = useContext(UserContext)
  const {userName,setUserName}=useContext(UserContext)
  const {socket,setSocket}=useContext(UserContext)
  const Email=useRef('')
  const Password=useRef('')

  const navigate=useNavigate ()

  useEffect(()=>{
    if (sessionStorage.getItem('Email')){
      sessionStorage.getItem('Email')
      sessionStorage.getItem('Password')
      login(sessionStorage.getItem('Email'),sessionStorage.getItem('Password'))
    }

  },[])




  const login=(Email,Password)=>{
    const loginObj={
      Email,
      Password
    }
    sessionStorage.removeItem('Email')
    sessionStorage.removeItem('Password')

    axios.post(process.env.REACT_APP_API_SERVER+'manager/login',loginObj).then(res=>{
      if (res.data.err==0){
        console.log(res.data.usr)
        setIsAuth(true)
        setUserToken(res.data.usr.CustomerUUID)
        setUserName(res.data.usr.Name)
        setLoginSuccess(true)
        setLoginError(false)
        setApiToken(res.data.usr.token)

        sessionStorage.setItem('isAuth',1)
        sessionStorage.setItem('userToken',res.data.usr.CustomerUUID)
        sessionStorage.setItem('userName',res.data.usr.Name)
        sessionStorage.setItem('mngapiToken',res.data.usr.token)
        sessionStorage.setItem('ProductTitle',res.data.usr.Title)
        sessionStorage.setItem('SportID',res.data.usr.SportID)
        sessionStorage.setItem('appCountries',JSON.stringify(res.data.usr.appCountries))
        sessionStorage.setItem('ChatUserUUID',res.data.usr.ChatUserUUID)

        if (res.data.usr.ChatUserUUID.length>0){
          socket.emit('user-connected',res.data.usr.ChatUserUUID)
        }
        


        setTimeout(() => {

          navigate('/dashboard')
      }, 3000); 
      }else{
        setIsAuth(false)
        // setUserToken(null)
        // setLoginSuccess(false)
        setMsg(res.data.msg)
        setLoginError(true)  
        setTimeout(() => {
            setLoginError(false) 
        }, 3000);            
      }
    })
  }

  const submit=(e)=>{
    e.preventDefault()
    login(Email.current.value,Password.current.value)

  }


  return (
    <Container >
          <br/>

          <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0',padding:'30px'}}>

          <Card.Body>
              <Card.Title>
              <Alert variant="success" style={{backgroundColor:'orange'}}>
                <table width="100%">
                  <tr>
                      <td width="120" align="center"><img src={Logo} height="100"/></td>
                      <td width="20"></td>
                      <td valign="middle" align="center"><h3>manager.onetwothreehigh.com</h3></td>
                      <td width="140"></td>
                  </tr>
                </table>
                
                
                </Alert>
              </Card.Title>
              <Card.Text>

                  <Form onSubmit={submit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" required ref={Email}/>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" required ref={Password} />
                  </Form.Group>
                  <p align="right">
                  <Button variant="dark" type="submit" style={{width:'200px'}} disabled={loginSuccess}>
                    Login
                  </Button>
                  </p>
                </Form>
              </Card.Text>

              <Card.Footer>
              {loginSuccess && 
                  <Alert variant="success">
                  Welcome {userName}. Please wait...
                  </Alert>                              
              } 

              {loginError && 
                  <Alert variant="danger">
                  {msg}
                  </Alert>                              
            }  
              </Card.Footer>
          </Card.Body>
          </Card>
       


             



    </Container>
  )
}
