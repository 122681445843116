import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import b1 from './b1.png'
import b2 from './b2.png'
import {UserContext} from '../context/UserContext'


export default function UserDetails(params) {
    const [usr,setUsr]=useState({})
    const [isLoading,setIsLoading]=useState(false)
    const {socket,setSocket}=useContext(UserContext)
    const [isOnline,setIsOnline]=useState(0)
    const navigate=useNavigate()


    useEffect(()=>{
        loadData()
        
        const intervalId = setInterval(() => {
            
                checkIfOnline(usr.id)

            
          }, 10000); // 1 second interval
      
          // Cleanup function to clear the interval
          return () => clearInterval(intervalId);
    },[])



    const checkIfOnline=async (id)=>{
        if (usr.id){
            await socket.emit('check-if-online',id)
            console.log(id)
        }

    }

    socket.on('checked-if-online',isOnline=>{
        setIsOnline(isOnline)
    })


    
    const loadData=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/userprofile/'+params.uuid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            const yearNow=new Date().getFullYear()
            res.data.results.Age=yearNow-res.data.results.DateOfBirthYMD.split('-')[0]
            //console.log(res.data.results)
           setUsr(res.data.results)
           checkIfOnline(res.data.results.id)
           setIsLoading(false)
        }).catch(err=>{
            setIsLoading(false)
    
        })          
    }


    const createRoom=()=>{
        const obj={
          HostID:sessionStorage.getItem('ChatUserUUID'),
          GuestID:params.uuid
        }
    
        console.log(obj)

        axios.post(process.env.REACT_APP_API_SERVER+'manager/rooms',obj,{
          headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
            }  
        }).then(async res=>{
         //console.log(res.data)
          if (res.data.err==0){
            console.log(res.data.RoomIDUUID)
            await socket.emit('join-room',res.data.RoomIDUUID)
            navigate('/chatroom/'+res.data.RoomIDUUID)
    
          }else{
            alert('An error has occured')
          }
        })
      }


  return (
    <Container fluid>

        <Card style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0'}}>

        <Card.Body>
            <Card.Title>
                <p align="center">

                    <div style={{
                        width: '150px',  /* Adjust the size as needed */
                        height: '150px', /* Adjust the size as needed */
                        borderRadius: '50%',
                        borderColor:'gray',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundImage:`url(${process.env.REACT_APP_API_SERVER}files/${params.uuid}/123)`                  
                    }}/>                    
                    <br/>
                    <h5 style={{color:'#b9b7c0'}}>{usr.FirstName} {usr.LastName}</h5>

                    <table width="100%">
                        <tr>
                            <td width="33%"></td>
                            <td width="33%" height="35" align="center" valign="middle" bgColor="#f2f2f3" style={{borderRadius: '10px'}}>
                            <font face="arial" size="2" color="#afb0b4">
                            {usr.Position}
                            </font>
                            </td>
                            <td width="33%"></td>
                        </tr>
                    </table>
                    <br/><br/>
                    <table width="100%">
                        <tr>
                            <td width="30"></td>
                            <td valign="top" align="center">
                                <table>
                                    <tr>
                                        <td valign="top"><img src={b1}/></td>
                                        <td width="10"></td>
                                        <td valign="top">
                                            <font face="arial" color="#afb0b4" size="2">
                                            <strong>{usr.xNumOfPosts}</strong><br/>
                                            Posts
                                            </font>
                                        </td>
                                    </tr>
                                </table>
                                

                            </td>
                            <td valign="top" align="center">
                            <table>
                                    <tr>
                                        <td valign="top"><img src={b2}/></td>
                                        <td width="10"></td>
                                        <td valign="top">
                                            <font face="arial" color="#afb0b4" size="2">
                                            <strong>{usr.xNumOfChallenges}</strong><br/>
                                            Complete Challenges
                                            </font>
                                        </td>
                                    </tr>
                                </table>

                            </td>
                            <td width="30"></td>
                        </tr>
                    </table>

                </p>
                <hr/>
            </Card.Title>
            <Card.Text>
            <font face="arial" color="#afb0b4" size="2">DETAILS</font>
            <br/><br/>
            <div style={{fontFamily:'Arial, Helvetica, sans-serif',color:'#6f6b7d',fontSize:'16px'}}>
            <p style={{lineHeight:'10px'}}><strong>Age:</strong> {usr.Age}</p>
            <p style={{lineHeight:'10px'}}><strong>Tag:</strong> {usr.Nickname}</p>
            <p style={{lineHeight:'10px'}}><strong>Height (cm):</strong> {usr.Height}</p>
            <p style={{lineHeight:'10px'}}><strong>Weight (Kgr):</strong> {usr.Weight}</p>
            <p style={{lineHeight:'10px'}}><strong>Country:</strong> {usr.Country}</p>
            <p style={{lineHeight:'10px'}}><strong>Orientation:</strong> {usr.LeftRight==1 ? 'Right Handed/Footed' : usr.LeftRight==2 ? 'Left Handed/Footed' : 'Both Left & Right Handed/Footed'}</p>
            <p style={{lineHeight:'10px'}}><hr/><i>{usr.PersonalInfo}</i></p>
            {isOnline==1 &&
            <p style={{lineHeight:'10px'}}><strong>Status:</strong> <Button style={{backgroundColor:'#DFF7E9',color:'#2CC872'}}>Active</Button></p>
            
            }
            </div>
            <br/>
            {sessionStorage.getItem('ChatUserUUID').length>0 &&
            <p align="center">
                <Button onClick={()=>createRoom()} variant='success' style={{backgroundColor:'#7367F0',width:'100px',boxShadow:'0 0.125rem 0.25rem rgba(165, 163, 174, 0.3)'}}>Chat</Button>
            </p>
            }

            </Card.Text>

        </Card.Body>
        </Card>
    </Container>
  )
}
