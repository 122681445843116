import React,{useContext, useEffect} from 'react'
import axios from 'axios'
import {Link,NavLink} from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Dropdown} from 'react-bootstrap';
import logo from '../logo.png'
import person from './person.png'
import { FaHome, FaLink, FaUser,FaList} from 'react-icons/fa';


export default function DashboardNav() {
    const {userName}=useContext(UserContext)
    const {setIsAuth} = useContext(UserContext)
    const {userStatus}= useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)


    const logout=()=>{
        setIsAuth(false)
        sessionStorage.setItem("isAuth",0)
        sessionStorage.removeItem('isAuth')
        sessionStorage.removeItem('userToken')
        sessionStorage.removeItem('userName')
        sessionStorage.removeItem('mngapiToken')
        sessionStorage.removeItem('ProductTitle')
        sessionStorage.removeItem('SportID')
        sessionStorage.removeItem('appCountries')

    }

  return (
    <Navbar collapseOnSelect expand="lg"  sticky="top" className="bg-body-tertiary">

        <Navbar.Brand>
            <Link to="/dashboard"><img src={logo} style={{width:'13vh', marginTop: -7,marginLeft:20}} /></Link>
            &nbsp;
            <b>{sessionStorage.getItem('ProductTitle')}</b>
                
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/#/dashboard">Dashboard</Nav.Link>

            <NavDropdown title="Apps" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Chat with players</NavDropdown.Item> */}
              <NavDropdown.Item href="/#/lists">
                <FaList /> Players' Lists
              </NavDropdown.Item>

            </NavDropdown>


            {/* <Nav.Link href="#link">Link</Nav.Link> */}
            {/* <NavDropdown title="User Settings" id="basic-nav-dropdown"> */}
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item href="#" onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>

          <Nav className="ms-auto" style={{padding:20}}>
          <Dropdown>
            <Dropdown.Toggle as={Navbar.Brand} id="dropdown-custom-components">
              <img
                src={person}
                width="40"
                height="40"
                className="d-inline-block align-top"
                alt="Brand logo"
              />

            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              <Dropdown.Item><b>{sessionStorage.getItem('userName')}</b></Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#">My Profile</Dropdown.Item>
              <Dropdown.Item href="/#/billings">Billings</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#" onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </Nav>          
        </Navbar.Collapse>

    </Navbar>
  )
}
